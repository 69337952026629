

















































import {Component, Vue} from 'vue-property-decorator';
import {areaList} from '@vant/area-data';
import ShopImg from '@/components/ShopImg.vue'

@Component({
  components: {
    ShopImg
  }
})
export default class Home extends Vue {

  private areaList = areaList;
  private areaShow = false;

};
