










import {Component, Vue} from 'vue-property-decorator';
import {Lazyload} from 'vant'
import AdApi from '@/api/ad'

Vue.use(Lazyload)

@Component({})
export default class ShopImg extends Vue {
  private api = new AdApi()

  private images = []

  private async activated() {
    await this.getAdList();
  }

  private async getAdList() {
    const result = await this.api.getAdList(1)
    if (result) {
      this.images = result.items.map((val: any) => val.fileUrl)
    }
  }
}
