import request from '@/utils/request'

const url = '/keep-fit/ad-management'

export default class AdApi {
  public async getAdList(site?: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url,
        method: 'get',
        params: {
          site
        }
      }).then((res) => resolve(res.data)).catch((error) => reject(error))
    })
  }

  public async getImgAsync(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      request({
        url: `${url}/${id}/imgs`,
        method: 'get'
      }).then((res) => resolve(res.data)).catch((err) => reject(err));
    });
  }
}
